import { NationalityResourceInterface } from '@interfaces/nationalities/nationalities-resource.interface';

/**
 * NationalitiesResponseModel
 */
export class NationalityResourceModel {
  id?: number;
  name?: string;

  constructor(data: NationalityResourceInterface) {
    this.id = data.id;
    this.name = data.name;
  }
}
