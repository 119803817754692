import { Injectable } from '@angular/core';

import { NationalitiesRepository } from './nationalities.repository';

@Injectable({
  providedIn: 'root'
})
export class NationalitiesService {
  constructor(private nationalitiesRepository: NationalitiesRepository) {}

  getNationalities() {
    return this.nationalitiesRepository.getNationalities();
  }
}
