import { PersonResourceInterface } from '../../interfaces/person-resource/person-resource.interface';
import { TypeOfPersonResourceModel } from '../type-of-person/type-of-person-resource.model';

/**
 * PersonResourceModel
 */
export class PersonResourceModel {
  id?: number;
  typeOfPerson?: TypeOfPersonResourceModel;
  name?: string;
  firstSurname?: string;
  secondSurname?: string;
  nationality?: number;
  login?: string;
  email?: string;
  emailTravel?: string;
  departmentId?: number;
  identityDocumentNumber?: string;
  identityDocumentExpirationDate?: string;
  passportDocumentNumber?: string;
  passportDocumentExpirationDate?: string;
  phoneNumber?: string;
  phoneNumberPrefix?: string;
  phoneNumberTravel?: string;
  phoneNumberTravelPrefix?: string;
  isManually?: boolean;

  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: PersonResourceInterface) {
    this.id = data.id;
    this.typeOfPerson = data.typeOfPerson ? new TypeOfPersonResourceModel(data.typeOfPerson) : undefined;
    this.name = data.name;
    this.firstSurname = data.firstSurname;
    this.secondSurname = data.secondSurname;
    this.nationality = data.nationality;
    this.login = data.login;
    this.email = data.email;
    this.emailTravel = data.emailTravel;
    this.departmentId = data.departmentId;
    this.identityDocumentNumber = data.identityDocumentNumber;
    this.identityDocumentExpirationDate = data.identityDocumentExpirationDate;
    this.passportDocumentNumber = data.passportDocumentNumber;
    this.passportDocumentExpirationDate = data.passportDocumentExpirationDate;
    this.phoneNumber = data.phoneNumber;
    this.phoneNumberPrefix = data.phoneNumberPrefix;
    this.phoneNumberTravel = data.phoneNumberTravel;
    this.phoneNumberTravelPrefix = data.phoneNumberTravelPrefix;
    this.isManually = data.isManually;
  }
}
