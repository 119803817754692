import { NationalityResourceCollectionInterface } from '@interfaces/nationalities/nationalities-resource-collection.interface';

import { NationalityResourceModel } from './nationalities-resource.model';

/**
 * DepartmentResourceCollectionModel
 */
export class NationalityResourceCollectionModel {
  nationalities?: NationalityResourceModel[];
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: NationalityResourceCollectionInterface) {
    this.nationalities = data.nationalities
      ? data.nationalities.map((nationality) => new NationalityResourceModel(nationality))
      : [];
  }
}
