import { NationalityResourceCollectionResponseInterface } from '@interfaces/nationalities/nationalities-resource-collection-response.interface';

import { NationalityResourceCollectionModel } from './nationalities-resource-collection.model';

/**
 * DepartmentResourceCollectionResponseModel
 */
export class NationalityResourceCollectionResponseModel {
  data: NationalityResourceCollectionModel;
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: NationalityResourceCollectionResponseInterface) {
    this.data = new NationalityResourceCollectionModel(data.data);
  }
}
