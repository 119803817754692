import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NationalityResourceCollectionResponseInterface } from '@interfaces/nationalities/nationalities-resource-collection-response.interface';
import { NationalityResourceCollectionResponseModel } from '@models/nationalities/nationalities-resource-collection-response.model';

import { Repository } from '../abstract.repository';

@Injectable({
  providedIn: 'root'
})
export class NationalitiesRepository extends Repository {
  baseUrl = `${this.basePath}/nationalities`;

  constructor(injector: Injector) {
    super(injector);
  }

  getNationalities(): Observable<NationalityResourceCollectionResponseModel> {
    return this.doRequest<NationalityResourceCollectionResponseInterface>('get', `${this.baseUrl}`).pipe(
      map((response) => new NationalityResourceCollectionResponseModel(response))
    );
  }
}
