import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { AirplaneResourceCollectionResponseInterface } from '@interfaces/airplane/airplane-resource-collection-response.interface';
import { AirplaneResourceInterface } from '@interfaces/airplane/airplane-resource.interface';
import { CancellationReasonResourceInterface } from '@interfaces/cancelation-reason/cancellation-reason-resource.interface';
import { DepartmentResourceCollectionResponseInterface } from '@interfaces/departament-resource/department-resource-collection-response.interface';
import { DepartmentResourceInterface } from '@interfaces/departament-resource/department-resource.interface';
import { NationalityResourceCollectionResponseInterface } from '@interfaces/nationalities/nationalities-resource-collection-response.interface';
import { NationalityResourceInterface } from '@interfaces/nationalities/nationalities-resource.interface';
import { PersonResourceResponseInterface } from '@interfaces/person-resource/person-resource-response.interface';
import { SocietyResourceInterface } from '@interfaces/society-resource/society-resource.interface';
import { TypeOfPersonResourceInterface } from '@interfaces/type-of-person/type-of-person-resource.interface';
import { PersonResourceResponseModel } from '@models/person-resource/person-resource-response.model';
import { AirplanesService } from '@pages/g200/services/airplanes/airplanes.service';

import { MSafeAny } from '@mercadona/common/private';

import { CancellationReasonsService } from '../cancellation-reasons/cancellation-reasons.service';
import { DepartmentsService } from '../departments/departments.service';
import { NationalitiesService } from '../nationalities/nationalities.service';
import { SocietiesService } from '../societies/societies.service';
import { StatesStageService } from '../states-stage/states-stage.service';
import { TypesOfPersonsService } from '../types-of-persons/types-of-persons.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalCacheService {
  cancellationReasons: CancellationReasonResourceInterface[] = [
    { id: 1, code: 'CAMBIO', name: 'Cambio de planificación (MCD)' },
    { id: 2, code: 'NO DISPONIBLE', name: 'No disponibilidad (Presidencia)' },
    { id: 3, code: 'MANT', name: 'Mantenimiento' },
    { id: 4, code: 'METEO', name: 'Meteorología' }
  ];
  typesOfPersons: BehaviorSubject<TypeOfPersonResourceInterface[]> = new BehaviorSubject<
    TypeOfPersonResourceInterface[]
  >([]);
  nationalities: BehaviorSubject<NationalityResourceInterface[]> = new BehaviorSubject<NationalityResourceInterface[]>(
    []
  );
  societies: BehaviorSubject<SocietyResourceInterface[]> = new BehaviorSubject<SocietyResourceInterface[]>([]);
  departments: BehaviorSubject<DepartmentResourceInterface[]> = new BehaviorSubject<DepartmentResourceInterface[]>([]);
  airplanes: BehaviorSubject<AirplaneResourceInterface[]> = new BehaviorSubject<AirplaneResourceInterface[]>([]);
  user: BehaviorSubject<PersonResourceResponseInterface> = new BehaviorSubject<PersonResourceResponseInterface>({});
  statesStage: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  stageStatesTransitions: BehaviorSubject<MSafeAny[]> = new BehaviorSubject<MSafeAny[]>([]);
  isPermitedFlow: boolean = false;

  constructor(
    private typesOfPersonsService: TypesOfPersonsService,
    private societiesService: SocietiesService,
    private departmentsService: DepartmentsService,
    private airplanesService: AirplanesService,
    private cancellationService: CancellationReasonsService,
    private statesStageService: StatesStageService,
    private nationalitiesService: NationalitiesService
  ) {}

  initServices() {
    this.initCancellationReasons();
    this.initAirplanes();
    this.initDepartments();
    this.initTypeOfPerson();
    this.initSocieties();
    this.initStatesStage();
    this.initStageStatesTransitions();
    this.initNationalities();
  }

  setUser(user: PersonResourceResponseModel) {
    this.user.next(user);
  }

  private initCancellationReasons() {
    this.cancellationService
      .getCancellationReasons(1, 10)
      .pipe(first())
      .subscribe((res) => {
        if (res.data?.cancellationReasons) {
          this.cancellationReasons = res.data.cancellationReasons;
        }
      });
  }

  private initAirplanes() {
    const pageSize = 30;

    this.airplanesService
      .getAirplanes(undefined, pageSize)
      .pipe(first())
      .subscribe((airplanes: AirplaneResourceCollectionResponseInterface) => {
        if (airplanes.data.airplanes) {
          this.airplanes.next(airplanes.data.airplanes);
        }
      });
  }

  private initDepartments() {
    this.departmentsService
      .getDepartments({ pageSize: 100 })
      .subscribe((departments: DepartmentResourceCollectionResponseInterface) => {
        if (departments.data.departments) {
          this.departments.next(departments.data.departments);
        }
      });
  }

  private initTypeOfPerson() {
    this.typesOfPersonsService
      .getTypesOfPersons()
      .pipe(
        map((res) => res.data.typesOfPersons || []),
        first()
      )
      .subscribe((types) => {
        this.typesOfPersons.next(types);
      });
  }

  private initNationalities() {
    this.nationalitiesService
      .getNationalities()
      .subscribe((nationalities: NationalityResourceCollectionResponseInterface) => {
        if (nationalities?.data.nationalities) {
          this.nationalities.next(nationalities.data.nationalities);
        }
      });
  }

  private initSocieties() {
    this.societiesService
      .getSocieties({ pageSize: 100 })
      .pipe(
        map((res) => res.data.societies || []),
        first()
      )
      .subscribe((societies) => {
        this.societies.next(societies);
      });
  }

  private initStatesStage() {
    this.statesStageService
      .getStatesStage()
      .pipe(
        map((res) => res || []),
        first()
      )
      .subscribe((states) => {
        if (states) {
          this.statesStage.next(states);
        }
      });
  }

  private initStageStatesTransitions() {
    this.statesStageService.getStageStatesTransitions().subscribe((states: MSafeAny) => {
      if (states) {
        this.stageStatesTransitions.next(states.data);
      }
    });
  }
}
